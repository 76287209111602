<template>
    <div class="wrapper" :class="{ 'no-login': $route.name != 'login' }">
        <b-card class="border-0" no-body>
            <h1 slot="header" class="mb-0">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    class="logo app-logo">
                    <path
                        d="M50,0A50,50,0,0,0,2.66,66.12l.19.56.59,0A31.5,31.5,0,0,1,33.29,96.56l0,.59.56.19A50,50,0,1,0,50,0Zm0,98.25a48.13,48.13,0,0,1-15-2.38A33.23,33.23,0,0,0,4.13,65,48.26,48.26,0,1,1,50,98.25Zm0-9.87L85.94,26.13H14.06Zm0-7L20.12,29.63H79.88Zm0-4.11L76.32,31.68H23.68Zm0-5L28,34.18H72Zm0-5.1L67.57,36.73H32.43Zm0-5L36.76,39.23H63.24Z" />
                </svg>
                <span class="app-name">{{ appName }}</span>
            </h1>
            <b-card-body class="text-white">
                <router-view />
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
export default {
    name: 'Authentication',
    props: {
        appName: {
            type: String,
            default: process.env.VUE_APP_NAME,
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.wrapper {
    padding-bottom: 3.35rem;
    @include background('../assets/hero.jpg');
}

.card {
    @include card-centered;

    .card-header {
        margin-bottom: 0.6rem;
        padding: 0.75rem 1rem;
        background: rgba($primary, 0.8);
        text-align: center;

        .no-login & {
            margin-bottom: 0;
            padding: 0;
            border: none;
        }

        h1 {
            color: darken($primary, 50%);
            font-weight: 400;
            transition: all 0.3s ease;

            .no-login & {
                display: none;
                z-index: 11;
                position: fixed;
                bottom: 0.6rem;
                left: 0.7rem;
                font-size: 1rem;
                -webkit-backface-visibility: hidden;

                @media (min-width: 576px) {
                    display: block;
                }
            }
        }

        .app-name {
            vertical-align: middle;

            .no-login & {
                display: none;

                @media (min-width: 576px) {
                    display: inline !important;
                }
            }
        }
    }

    .card-body {
        background: rgba($dark-green, 0.75);

        ::v-deep .invalid-feedback {
            display: inline;
            float: right;
            width: auto;
            color: $light-red;
        }

        ::v-deep h2 {
            margin-bottom: 0.8rem;
            text-align: center;
            font-weight: 300;
        }

        ::v-deep p {
            text-align: center;
        }
    }
}

@media only screen and (min-height: 600px) {
    .wrapper {
        @include screen-centered;
    }
    .card {
        width: 375px;
    }
}
</style>
